.successButton {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    width: 158px;
    height: 41px;
    flex-shrink: 0;   
}

.successButtonChild {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-dodgerblue);
    width: 158px;
    height: 41px;
  }

  .success {
    position: absolute;
    top: 13px;
    left: 26px;
    font-size: var(--font-size-base);
    font-family: var(--font-ibm-plex-mono);
    color: var(--color-white);
    text-align: left;
  }