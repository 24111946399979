.error {
  color: red;
}
.price {
  position: relative;
}
.sectionconfirmChild {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  height: 28px;
  flex-shrink: 0;
}
.confirm {
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-ibm-plex-mono);
  color: var(--color-white);
  text-align: left;
}
.confirmbutton,
.confirmbutton1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-sm) var(--padding-md) var(--padding-sm)
    var(--padding-lg);
  background-color: var(--color-dodgerblue);
  width: 1px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-width: 50%;
}
.deploybuttongroup,
.sectionconfirm {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-xs);
}
.sectionconfirm {
  flex-direction: column;
  gap: var(--gap-md);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-ibm-plex-mono);
}
@media screen and (max-width: 1200px) {
  .sectionconfirmChild {
    background-color: var(--color-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  }
  .confirmbutton1 {
    min-width: 50%;
  }
  .deploybuttongroup {
    gap: var(--gap-0);
  }
  .deploybuttongroup,
  .sectionconfirm {
    align-self: stretch;
    width: auto;
  }
}
