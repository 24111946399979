.persealogoIcon {
  position: relative;
  width: 180px;
  height: 51px;
  flex-shrink: 0;
  object-fit: cover;
}
.navleft {
  width: 180px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 50%;
  max-width: 50%;
}
.connectWalletButtonChild {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-dodgerblue);
  width: 158px;
  height: 41px;
}
.connectWallet {
  position: absolute;
  top: 13px;
  left: 36px;
  font-size: var(--font-size-base);
  font-family: var(--font-ibm-plex-mono);
  color: var(--color-white);
  text-align: left;
}
.connectWalletButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 158px;
  height: 41px;
  flex-shrink: 0;
}
.navrigth,
.topbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navrigth {
  width: 158px;
  flex-shrink: 0;
  justify-content: flex-end;
  min-width: 50%;
}
.topbar {
  background-color: var(--color-white);
  width: 100px;
  padding: 7px 9px;
  box-sizing: border-box;
  justify-content: flex-start;
  min-width: 100%;
}
.imageAsset1Icon,
.title {
  align-self: stretch;
  position: relative;
}
.title {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  text-align: center;
  display: inline-block;
  height: 26px;
  flex-shrink: 0;
}
.imageAsset1Icon {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.collectionname {
  align-self: stretch;
  height: 1px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 350px;
}
.coleccinName {
  position: relative;
}
.name {
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: inherit;
  font-family: inherit;
}
.sidebarminter {
  background-color: var(--color-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 1px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
  min-width: 20%;
}
.youViewFrom {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.batchmintpanel,
.sectionpreviewnft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sectionpreviewnft {
  width: 60px;
  flex-shrink: 0;
  flex-direction: column;
  min-width: 60%;
  font-family: var(--font-inter);
}
.batchmintpanel {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  gap: 140px;
  font-size: var(--font-size-base);
}
.mintBatchAnd {
  margin-block-start: 0;
  margin-block-end: 0;
}
.loremIpsumIs,
.mintBatchAndContainer {
  margin: 0;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}
.mintBatchAndContainer {
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  width: 390px;
  height: 21px;
}
.loremIpsumIs {
  width: 515px;
}
.description {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-dodgerblue);
  width: 61px;
  height: 41px;
}
.use {
  position: absolute;
  top: 13px;
  left: 18px;
  font-size: var(--font-size-base);
  font-family: var(--font-ibm-plex-mono);
  color: var(--color-white);
  text-align: left;
  display: inline-block;
  width: 23px;
  height: 15px;
}
.rectangleParent {
  position: relative;
  width: 61px;
  height: 41px;
  flex-shrink: 0;
}
.useminttypebutton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sectiondescription {
  border-top: 1px solid var(--color-black);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size-lg);
}
.cardTypeOfMint,
.sectionselectminttype {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cardTypeOfMint {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: var(--padding-xs);
  align-items: flex-start;
  gap: var(--gap-sm);
}
.sectionselectminttype {
  height: 872px;
  flex-shrink: 0;
  padding: 15px 0;
  box-sizing: border-box;
  align-items: center;
  min-height: 800px;
  max-height: 800px;
  overflow-y: scroll;
}
.emptyCollections {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.sectioncollection {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 11px;
}
.home,
.sectionlogin {
  align-self: stretch;
  flex-shrink: 0;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 900px;
  max-height: 900px;
}
.sectionlogin {
  position: relative;
  background-color: var(--color-white);
  min-height: 600px;
  max-height: 100%;
}
.footerIcon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 89px;
}
.footer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.parentcontainer {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  min-width: 100%;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-black);
  font-family: var(--font-ibm-plex-mono);
}
@media screen and (max-width: 1200px) {
  .navleft {
    height: auto;
    gap: var(--gap-0);
    min-width: 50%;
  }
  .navleft,
  .navrigth {
    width: 50%;
  }
  .topbar {
    height: auto;
    min-width: 100%;
  }
  .imageAsset1Icon,
  .title {
    align-self: stretch;
    width: auto;
  }
  .title {
    font-weight: 700;
    font-family: var(--font-ibm-plex-mono);
    text-align: center;
  }
  .imageAsset1Icon {
    flex: 1;
  }
  .collectionname {
    flex: 1;
    min-height: 10%;
  }
  .collectionname,
  .name {
    align-self: stretch;
    width: auto;
  }
  .sidebarminter {
    height: auto;
    min-width: 20%;
  }
  .sectionpreviewnft {
    align-items: center;
    justify-content: flex-start;
    min-width: 60%;
  }
  .batchmintpanel {
    align-self: stretch;
    width: auto;
    min-height: 100%;
  }
  .cardTypeOfMint {
    width: auto;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
  .sectionselectminttype {
    width: auto;
    gap: var(--gap-0);
    max-height: 800px;
  }
  .emptyCollections {
    align-self: stretch;
    width: auto;
  }
  .sectioncollection {
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer,
  .home,
  .sectioncollection {
    align-self: stretch;
    width: auto;
  }
  .home {
    min-height: 700px;
    max-height: 700px;
  }
  .footer {
    flex: 1;
    align-items: flex-end;
    justify-content: center;
  }
  .parentcontainer {
    width: auto;
    height: auto;
    min-height: 100%;
  }
}
