@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-ibm-plex-mono: "IBM Plex Mono";
  --font-inter: Inter;

  /* font sizes */
  --font-size-base: 12px;
  --font-size-lg: 21px;
  --font-size-xl: 24px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-dodgerblue: #048dfc;

  /* Gaps */
  --gap-0: 0px;
  --gap-xs: 1px;
  --gap-sm: 10px;
  --gap-md: 12px;
  --gap-lg: 13px;
  --gap-xl: 14px;
  --gap-2xl: 22px;

  /* Paddings */
  --padding-xs: 10px;
  --padding-sm: 13px;
  --padding-md: 28px;
  --padding-lg: 36px;
}
