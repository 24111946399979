.NFTCard {
    max-width: 300px;
    min-height: 450px;
    max-height: 450px;
    border: 1px solid;
    margin: 2px;
    overflow-y: auto;
}

.collectionnameIcon {
    align-self: stretch;
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.coleccinName,
.name {
  margin: 0 5px;
  font-size: inherit;
  font-family: inherit;
}

.coleccinName {
    flex: 1;
    position: relative;
    font-weight: 400;
  }
  .name {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .metadata {
    margin: 2px 2px;
    border: 1px solid;
    text-align: center;
    font-size: 12px;
  }