.updateButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-dodgerblue);
  position: relative;
  width: 158px;
  height: 41px;
  flex-shrink: 0;
}
.updateButtonText {
  position: absolute;
  top: 13px;
  left: 56px;
  font-size: var(--font-size-base);
  font-family: var(--font-ibm-plex-mono);
  color: var(--color-white);
  text-align: center;
}