
.youViewFrom {
    margin: 0;
    position: relative;
    font-size: inherit;
    font-weight: 400;
    font-family: inherit;
}

.containerNFTs {
    align-self: stretch;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs) 0;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: 700px;
    overflow-y: auto;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-black);
    font-family: var(--font-ibm-plex-mono);
    margin:5px;
}