.error {
  color:red;
}
.csvLink {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.sectionpreviewlinksChild,
.sectionpreviewlinksItem {
  border: 0;
  background-color: var(--color-white);
  align-self: stretch;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  height: 28px;
  flex-shrink: 0;
}
.preview {
  position: relative;
  font-size: var(--font-size-base);
  font-family: var(--font-ibm-plex-mono);
  color: var(--color-white);
  text-align: left;
}
.previewbutton,
.sectionpreviewlinks {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.previewbutton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-sm) var(--padding-lg);
  background-color: var(--color-dodgerblue);
  flex-direction: row;
  align-items: center;
}
.sectionpreviewlinks {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-ibm-plex-mono);
}
@media screen and (max-width: 1200px) {
  .csvLink {
    align-self: stretch;
    width: auto;
  }
  .sectionpreviewlinksItem {
    background-color: var(--color-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  }
  .previewbutton,
  .sectionpreviewlinks,
  .sectionpreviewlinksItem {
    align-self: stretch;
    width: auto;
  }
  .sectionpreviewlinks {
    flex: 1;
    align-items: flex-start;
    justify-content: center;
  }
}
